import React, { useState } from "react";
import CanDropdown from "../Reusable/CanDropdown";
// import DownloadCsv from "./DownloadCsv";
// import arrow from "../../assets/dropdown-arrow-grey.svg";
import schedule from "../../assets/calender.svg";
import SearchInput from "../Reusable/SearchInput";
import search from "../../assets/Search.svg";
import { TailSpin } from "react-loader-spinner";
import { getThemeColor } from "../Functions/commonFunctions";
import { Tooltip } from "react-tooltip";
import CandidateATS from "./CandidateATS";
import { useLocation } from "react-router";

const CandidateHeader = ({
  filteredItems,
  candidates,
  openOffcanvas,
  job_role,
  changeJobId,
  canlist,
  onSearchData,
  jobId,
  searchLoading,
  searchText,
  setSearchText,
  modalTitle,
  setModalTitle,
  setShowAddJob,
  showAddJob,
  handleAddJobModal,
  getCandidateDetails,
  singleCanDetails,
  setSingleCanDetails,
  handleJobIdChange,
  jobList,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValueJob, setSelectedValueJob] = useState(null);
  const [filterText, setFilterText] = useState("All");
  // const [jobId1, setJobId1] = useState("");

  const DropdownArr = [
    { value: "skills", label: "Skills" },
    { value: "location", label: "Location" },
    { value: "aligned", label: "Aligned" },
    { value: "experience", label: "Experience" },
    { value: "overall_rating", label: "Overall-Rating" },
    { value: "name", label: "Name" },
    { value: "interview_status", label: "Interview Status" },
    { value: "review_status", label: "Review Status" },
  ];

  const statusArr = [
    { value: "completed", label: "Completed" },
    { value: "scheduled", label: "Scheduled" },
    { value: "in_complete", label: "Incomplete" },
    { value: "in_progress", label: "In Progress" },
  ];

  const reviewArr = [
    { value: "completed", label: "Completed" },
    { value: "in_progress", label: "In Progress" },
  ];

  const handleSelectedValue = (eventKey) => {
    setFilterText(eventKey);
    const selectedData = DropdownArr.filter((item) => item.value === eventKey);
    setSelectedValue(selectedData[0]?.label);
    if (eventKey === "All") {
      onSearch(eventKey);
    }
  };

  const handleJobSelectedValue = (eventKey) => {
    setSelectedValueJob(eventKey);
    const jobData = jobList.filter((item) => item.label === eventKey);
    localStorage.setItem("jobIntialId", jobData[0]?.id);
    localStorage.setItem('currentJob',jobData[0]?.id)
    handleJobIdChange(jobData[0]?.id);
  };

  const rating = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];

  const alignedStatus = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const onFilter = (value) => {
    setSearchText(value);
  };

  const onSearch = (eventKey) => {
    let sertchValue = "";
    if (filterText === "aligned") {
      sertchValue = searchText;
    } else if (filterText === "experience") {
      sertchValue = Number(searchText);
    } else {
      sertchValue = searchText;
    }

    const obj = {
      job_order_id: jobId ? jobId : changeJobId,
    };
    if (eventKey !== "All") {
      obj.criteria = filterText;
      obj.search_value = sertchValue;
    }
    // if(eventKey !== 'All'){
    obj.criteria = filterText.toLowerCase();
    obj.search_value = sertchValue;

    // }else{
    //   obj.search_value= sertchValue;
    // }
    onSearchData(obj);
  };

  const searchButton = (array) => {
    return (
      <div className="filter-div-search">
        <select
          className="filter-search-dropdown"
          onChange={(e) => onFilter(e.target.value)}
        >
          <option value="">Select</option>
          {array.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </select>

        {searchLoading ? (
          <div className="search-icon-filter">
            <TailSpin
              visible={true}
              height="20"
              width="20"
              color={getThemeColor().primaryColor}
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <button
            onClick={(filterText) => onSearch(filterText)}
            className="search-icon-filter"
          >
            <img src={search} alt="" />
          </button>
        )}
      </div>
    );
  };

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const view = searchParams.get("type");

  return (
    <>
      <Tooltip
        id="candidate-header-tooltip"
        variant="light"
        className="candidate-tooltip"
        border="1px solid #7C7B7B"
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          gap: "12px",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-start gap-3"
          style={{ width: "25%" }}
        >
          <CandidateATS
            filteredItems={filteredItems}
            setModalTitle={setModalTitle}
            modalTitle={modalTitle}
            setShowAddJob={setShowAddJob}
            showAddJob={showAddJob}
            handleAddJobModal={handleAddJobModal}
            jobId={jobId}
            getCandidateDetails={getCandidateDetails}
            singleCanDetails={singleCanDetails}
            setSingleCanDetails={setSingleCanDetails}
            changeJobId={changeJobId}
          />
        </div>
        <div className="candidate-list-label">
          <div>Candidates List </div>

          <div className="mx-2">
            <CanDropdown
              selectedValue={selectedValueJob}
              handleSelectedValue={handleJobSelectedValue}
              DropdownArr={jobList}
              width="200px"
              defaultText={
                job_role !== " "
                  ? job_role
                  : view === "listall"
                  ? "select"
                  : "select"
              }
              backgroundColor="#ffff"
              color="#600097"
              border="#E9E9E9"
              disableDefault={true}
            />
          </div>

          <div>({canlist.length})</div>
        </div>

        <div className="d-flex gap-3">
          {candidates.length > 1 ? (
            <button
              id="imgId"
              onClick={() => openOffcanvas("multi", candidates[0])}
              className="top-schedule-btn py-2 px-3"
            >
              <img src={schedule} alt="calender-icon" />
              <span style={{ marginLeft: "10px" }}>Schedule Interview</span>
            </button>
          ) : (
            <div className="d-flex gap-3">
              <CanDropdown
                selectedValue={selectedValue}
                handleSelectedValue={handleSelectedValue}
                DropdownArr={DropdownArr}
                width="180px"
                defaultText={"All"}
                backgroundColor="#F7F7F7"
                color="Black"
                border="#E9E9E9"
              />
              {filterText === "aligned" && searchButton(alignedStatus)}

              {filterText === "overall_rating" && searchButton(rating)}

              {filterText === "interview_status" && searchButton(statusArr)}

              {filterText === "review_status" && searchButton(reviewArr)}
              {(filterText === "" ||
                filterText === "All" ||
                filterText === "location" ||
                filterText === "name" ||
                filterText === "skills" ||
                filterText === "experience") && (
                <div style={{ paddingInline: "0px" }}>
                  <SearchInput
                    onFilter={(e) => onFilter(e.target.value)}
                    onSearch={() => onSearch(filterText)}
                    enableOnClick={true}
                    searchLoading={searchLoading}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CandidateHeader;
