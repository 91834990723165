import React, { useState } from "react";
import "./CompanyList.css";
import import1 from "../../../src/assets/import-job.svg";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import { API } from "../../global";
import toast from "react-hot-toast";
import { GoFile } from "react-icons/go";
import BarLoader from "react-spinners/BarLoader";
import { IoClose } from "react-icons/io5";

const ImportCompany = (props) => {
  const {
    fileInputRef,
    importfile,
    setImportFile,
    importCompanyFile,
    setImportCompanyFile,
    handleImportCompanyModal,
    handleClose,
    CompanyList,
  } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const [bgFile, setBgFile] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");

  const handleBrowseClick = () => {
    fileInputRef.current.click();
    // simulateFileUpload();
  };

  const handleFileChange = (e) => {
    setResponseMsg("");
    setBgFile(false);
    setBtnDisable(false);

    const file = e.target.files[0];
    const fileName = file.name
      .split(".")[0]
      .replace(/[0-9()[\]{}]/g, "")
      .trim();

    console.log(fileName, "fileName");
    const allowedTypes = [
      "application/vnd.ms-excel",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]; // Allowed file types
    const maxSize = 10 * 1024 * 1024; // 10MB
    if (file && allowedTypes.includes(file.type) && file.size <= maxSize) {
      setFileError("");
      setImportFile(true);
      setImportCompanyFile(file);
      if (fileName === "CompanyList-Template") {
        const reader = new FileReader();
        reader.onload = (event) => {
          console.log(event.target.result, "event");
          const data = event.target.result;

          const workbook = XLSX.read(data, { type: "array" });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const json = XLSX.utils.sheet_to_json(worksheet);

          if (json.length !== 0) {
            const template = [
              "Company_Name",
              "Key_Technologies",
              "Website_URL",
              "Billing_Contact",
              "Address",
              "ContactNumber",
            ];

            const column = Object.keys(json[0]);

            const dataCount = column.length;

            const isMatch = column.every(
              (columnName, index) => columnName === template[index]
            );

            if (isMatch && dataCount === 6) {
              const newCompany = json.map(
                ({
                  Company_Name,
                  Key_Technologies,
                  Website_URL,
                  Billing_Contact,
                  Address,
                  ContactNumber,
                }) => ({
                  company_name: Company_Name,
                  address: Address,
                  contact_number: ContactNumber,
                  key_technologies: Key_Technologies,
                  billing_contact: Billing_Contact,
                  website_url: Website_URL,
                })
              );

              handlePostCompanylist(newCompany);
            } else {
              toast.error("File format is mismatched");
              setImportFile(false);
            }
          } else {
            setResponseMsg("Uploaded file has no data");
          }
        };
        if (e.target.files && e.target.files.length > 0) {
          reader.readAsArrayBuffer(e.target.files[0]);
        } else {
          toast.error("Please upload the file");
        }
      } else {
        setImportFile(false);
        toast.error("Please ensure the file name matches the template.");
      }
    } else {
      setImportFile(false);

      setFileError("Please select a valid file (CSV or XLSX) under 10MB.");
    }
  };

  const handlePostCompanylist = (newCompany) => {
    setLoading(true);
    setBtnDisable(true);
    fetch(`${API}/admin/company/import`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },

      body: JSON.stringify({ companies: newCompany }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setBtnDisable(false);

        if (res.success) {
          // fileInputRef.current.value = "";
          // handleImportJobModal();

          setBgFile(true);
          setResponseMsg("File uploaded successfully");
        } else {
          // toast.error("File format is mismatched");
          setBgFile(false);

          if (res.error.code === 421) {
            navigate("/error");
          } else {
            setResponseMsg(res.error.msg);
          }
          //   setJobListLoading(false);
        }
      });
  };

  const ErrorIcon = () => (
    <svg
      viewBox="0 0 200 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-2" transform="translate(2.000000, 2.000000)">
          <circle
            id="Oval-2"
            stroke="rgba(252, 191, 191, .5)"
            strokeWidth="5"
            cx="41.5"
            cy="41.5"
            r="41.5"
          ></circle>
          <circle
            className="ui-error-circle"
            stroke="#b01717"
            strokeWidth="5"
            cx="41.5"
            cy="41.5"
            r="41.5"
          ></circle>
          <path
            className="ui-error-line1"
            d="M22.244224,22 L60.4279902,60.1837662"
            id="Line"
            stroke="#b01717"
            strokeWidth="4"
            strokeLinecap="square"
          ></path>
          <path
            className="ui-error-line2"
            d="M60.755776,21 L23.244224,59.8443492"
            id="Line"
            stroke="#b01717"
            strokeWidth="4"
            strokeLinecap="square"
          ></path>
        </g>
      </g>
    </svg>
  );

  return (
    <>
      {importfile ? (
        <>
          <div className="import-upload-file mb-2">
            <div className="mb-1">
              {/* <b style={{ fontSize: "14px" }}>
                {" "}
                {uploadProgress === 100 ? "Uploaded File" : "Uploading File"}
              </b> */}
            </div>
            {responseMsg && (
              <>
                {responseMsg.includes("successfully") ? (
                  <span className="response-msg">
                    <div className="">
                      <svg
                        width="60"
                        height="60"
                        viewBox="0 0 200 200"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          fill="none"
                          stroke="#17b05d"
                          stroke-width="6"
                          cx="100"
                          cy="100"
                          r="90"
                          strokeLinecap="round"
                          transform="rotate(-90 100 100)"
                          class="circle"
                        />
                        <polyline
                          fill="none"
                          stroke="#17b05d"
                          points="44,114 86.5,142 152,69"
                          strokeWidth="6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          class="tick"
                        />
                      </svg>
                    </div>

                    <div>{responseMsg}</div>
                  </span>
                ) : (
                  <span className="response-msg-error">
                    <div className="ui-error ms-5">
                      <ErrorIcon />
                    </div>
                    <div>{responseMsg}</div>
                  </span>
                )}
              </>
            )}

            <div
              className={
                bgFile ? "upload-file-green mt-3" : "upload-file-card mt-3"
              }
            >
              <div className="d-flex gap-2 align-items-center justify-content-center p-2 w-100">
                {" "}
                <div>
                  <GoFile color={bgFile ? "#17b05d" : "#c9c9c9"} />
                </div>
                <div className="upload-file-name">{importCompanyFile.name}</div>
              </div>

              <div>
                {" "}
                {loading ? (
                  <div className="px-2">
                    <BarLoader color="#17b05d" width="150" />
                  </div>
                ) : (
                  <button
                    className="mx-1"
                    style={{ background: "none", border: "none" }}
                    onClick={() => handleClose()}
                  >
                    <IoClose size={20} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h5>Import Company</h5>
          <div className="import-company-sub-content mt-4 mb-1">
            Download the company list template, fill it with your data, and
            effortlessly upload for seamless spreadsheet integration
          </div>
          <div className="import-company-dragdrop">
            <img src={import1} alt="" className="mb-2" />
            <div className="import-company-dragdropContent">
              <b>
                <span>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <button
                    className="add-company-download-btn"
                    onClick={handleBrowseClick}
                  >
                    Click here to select a file
                  </button>
                </span>
              </b>
            </div>
            <p style={{ color: "red" }}>{fileError}</p>
            <div className="import-Company-sub-content">
              Supported formats .csv, .xlxs - Max file size 10MB
            </div>
          </div>
          <div className="import-download-temp py-3">
            {" "}
            <button className="add-company-download-btn">
              <a
                href={CompanyList}
                download="CompanyList-Template"
                target="_blank"
                rel="noreferrer"
                className="template-href"
              >
                Download Template
              </a>
            </button>
          </div>
        </>
      )}
      <div className="text-center mt-3">
        <button
          className="close-button-style"
          onClick={() => handleImportCompanyModal()}
          disabled={btnDisable}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default ImportCompany;
