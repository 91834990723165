import React, { useEffect, useState } from "react";

import "./AddQuestion.css";
import AddQuestionHeader from "./AddQuestionHeader";
import AddNewQuestionnaire from "./AddNewQuestionnaire";
import AddNewQuestionModal from "./AddNewQuestionModal";
import { API } from "../../../global";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ProgressBarComponent from "../../Reusable/ProgressBarComponent";

import toast from "react-hot-toast";
import LayoutMain from "../../Layout/LayoutMain";

const AddNewQuestion = () => {
  let { jobid } = useParams();

  const location = useLocation();

  // Access the search property of the location object, which contains the query parameters
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get("mode");
  const id = searchParams.get("id");
  const role = searchParams.get("role");

  // console.log(id,"id")
  useEffect(() => {
    if (mode === "edit") {
      setTitle("Edit");
      getQuestionnaire();
    }

    if (mode === "add") {
      setTitle("Add a new");
      getJobOrder();

      // eslint-disable-next-line
      if (jobid !== undefined) {
        setJobOrderID(jobid);
      }

      let id = localStorage.getItem("Questionnaire_ID");
      if (id !== null || id !== undefined) {
        getQuestionnaireById();
      }
    }
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  const [btnenabled, setBtnEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addqueslist, setAddQuesList] = useState(false);
  const [jobOrderList, setJobOrderList] = useState([]);
  const [jobOrderID, setJobOrderID] = useState("");
  const [jobname, setJobName] = useState("");

  const [questionnaireId, setQuestionnaireId] = useState(id || "");
  const [questionnaireName, setQuestionnaireName] = useState("");
  const [questionDetails, setQuestionDetails] = useState("");
  const [title, setTitle] = useState("Add a new");
  const [modalTitle, setModalTitle] = useState("Add");
  const [loading, setLoading] = useState(false);
  const [choosenRole, setChoosenRole] = useState(false);
  const [processing, setProcessing] = useState(false);

  const getJobOrder = () => {
    setLoading(true);
    setJobOrderID(role);

    if (role) {
      setChoosenRole(true);
    }
    fetch(`${API}/admin/job-order/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((jobData) => {
        if (jobData.success) {
          setJobOrderList(jobData.result);
          setLoading(false);
        } else {
          if (jobData.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(jobData.error.msg);
          }
        }
      })
      .catch((err) => console.error("API Failed:", err));
  };

  const getQuestionnaireById = () => {
    fetch(
      `${API}/admin/question/questionnaire/${localStorage.getItem(
        "Questionnaire_ID"
      )}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((ques) => {
        // console.log(ques,"ques.data.joborder_id")
        setJobOrderID(ques.data.joborder_id);
        setQuestionnaireName(ques.data.name);
      })
      .catch((err) => console.error("API Failed:", err));
  };

  const getQuestionnaire = () => {
    setLoading(true);
    fetch(
      `${API}/admin/questionnaire/list?questionnaire_id=${questionnaireId}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((val) => {
        setLoading(false);
        if (val.success) {
          setQuestionDetails(val?.result?.questionnaires?.questions);
          setQuestionnaireName(val?.result?.questionnaires?.name);
          setJobOrderID(val?.result?.questionnaires?.job_order_id?.job_role);
          setJobName(val?.result?.questionnaires?.job_order_id?.job_role);
          setAddQuesList(true);
        }
      });
  };

  return (
    <div className="add-ques-main-container position-relative">
      <LayoutMain index={3}>
        {loading ? (
          <>
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          </>
        ) : (
          <div className="add-ques-container m-4 p-4 rounded  h-100">
            <AddQuestionHeader
              btnenabled={btnenabled}
              setShowModal={setShowModal}
              title={title}
              setModalTitle={setModalTitle}
              processing={processing}
            />
            <AddNewQuestionnaire
              setBtnEnabled={setBtnEnabled}
              btnenabled={btnenabled}
              setShowModal={setShowModal}
              addqueslist={addqueslist}
              jobOrderList={jobOrderList}
              jobOrderID={jobOrderID}
              setJobOrderID={setJobOrderID}
              setQuestionnaireId={setQuestionnaireId}
              questionnaireName={questionnaireName}
              setQuestionnaireName={setQuestionnaireName}
              questionDetails={questionDetails}
              setModalTitle={setModalTitle}
              mode={mode}
              id={id}
              getQuestionnaire={getQuestionnaire}
              choosenRole={choosenRole}
              setProcessing={setProcessing}
              processing={processing}
              jobname={jobname}
            />
            {showModal && (
              <div
                className={`${showModal ? "d-block" : "d-none"} backdrop-edit`}
              >
                <AddNewQuestionModal
                  setShowModal={setShowModal}
                  setAddQuesList={setAddQuesList}
                  jobid={jobid}
                  questionnaireId={questionnaireId}
                  getQuestionnaire={getQuestionnaire}
                  questionDetails={questionDetails}
                  title={title}
                  modalTitle={modalTitle}
                />
              </div>
            )}
          </div>
        )}
      </LayoutMain>
    </div>
  );
};

export default AddNewQuestion;
