import React, { useState } from "react";
import { GoFile } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import BarLoader from "react-spinners/BarLoader";
import import1 from "../../../src/assets/import-job.svg";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";
import { API } from "../../global";
import { useNavigate } from "react-router";

const ImportJob = ({
  fileInputRef,
  importfile,
  setImportFile,
  importJobFile,
  setImportJobFile,
  handleImportJobModal,
  handleClose,
  JobOrderList,
}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const [bgFile, setBgFile] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");

  const handleBrowseClick = () => {
    fileInputRef.current.click();
    // simulateFileUpload();
  };

  const handleFileChange = (e) => {
    setResponseMsg("");
    setBgFile(false);
    setBtnDisable(false);

    const file = e.target.files[0];
    const fileName = file.name
      .split(".")[0]
      .replace(/[0-9()[\]{}]/g, "")
      .trim();

    console.log(fileName, "fileName");
    const allowedTypes = [
      "application/vnd.ms-excel",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]; // Allowed file types
    const maxSize = 10 * 1024 * 1024; // 10MB
    if (file && allowedTypes.includes(file.type) && file.size <= maxSize) {
      setFileError("");
      setImportFile(true);
      setImportJobFile(file);

      if (fileName === "Job-Order-Template") {
        const reader = new FileReader();
        reader.onload = (event) => {
          console.log(event.target.result, "event");
          const data = event.target.result;

          const workbook = XLSX.read(data, { type: "array" });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const json = XLSX.utils.sheet_to_json(worksheet);

          if (json.length !== 0) {
            const template = ["Joborder_Id", "Company", "Jobrole", "Location"];

            const column = Object.keys(json[0]);

            const dataCount = column.length;

            const isMatch = column.every(
              (columnName, index) => columnName === template[index]
            );

            if (isMatch && dataCount === 4) {
              const newjoborder = json.map(
                ({ Joborder_Id, Company, Jobrole, Location }) => ({
                  ats_joborder_id: Joborder_Id,
                  company_name: Company,
                  job_role: Jobrole,
                  location: Location,
                })
              );

              handlePostJoblist(newjoborder);
              // uploadData(newjoborder);
              // handleImportJobOrder(newjoborder);
            } else {
              toast.error("File format is mismatched");
              setImportFile(false);
            }
          } else {
            setResponseMsg("Uploaded file has no data");
          }
        };
        if (e.target.files && e.target.files.length > 0) {
          reader.readAsArrayBuffer(e.target.files[0]);
        } else {
          toast.error("Please upload the file");
        }
      } else {
        setImportFile(false);
        toast.error("Please ensure the file name matches the template.");
      }
    } else {
      setImportFile(false);

      setFileError("Please select a valid file (CSV or XLSX) under 10MB.");
    }
  };

  const handlePostJoblist = (newjoborder) => {
    setLoading(true);
    setBtnDisable(true);
    fetch(`${API}/admin/job-order/import`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },

      body: JSON.stringify({ job_orders: newjoborder }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setBtnDisable(false);

        if (res.success) {
          // fileInputRef.current.value = "";
          // handleImportJobModal();

          setBgFile(true);
          setResponseMsg("File uploaded successfully");
        } else {
          // toast.error("File format is mismatched");
          setBgFile(false);

          if (res.error.code === 421) {
            navigate("/error");
          } else {
            setResponseMsg(res.error.msg);
          }
          //   setJobListLoading(false);
        }
      });
  };

  const SuccessIcon = () => (
    <svg
      width="60"
      height="60"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill="none"
        stroke="#17b05d"
        stroke-width="10"
        cx="100"
        cy="100"
        r="90"
        strokeLinecap="round"
        transform="rotate(-90 100 100)"
        className="circle"
      />
      <polyline
        fill="none"
        stroke="#17b05d"
        points="44,114 86.5,142 152,69"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="tick"
      />
    </svg>
  );

  const ErrorIcon = () => (
    <svg
      width="50"
      height="50"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle
        fill="none"
        stroke="#b01717"
        stroke-width="10"
        cx="100"
        cy="100"
        r="90"
        stroke-linecap="round"
        transform="rotate(-90 100 100)"
        className="circle"
      />

      <path
        className="ui-error-line1"
        d="M70,70 L130,130"
        stroke="#b01717"
        stroke-width="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        className="ui-error-line2"
        d="M130,70 L70,130"
        stroke="#b01717"
        stroke-width="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div>
      {" "}
      {importfile ? (
        <>
          <div className="import-upload-file mb-2">
            <div className="mb-1">
              {/* <b style={{ fontSize: "14px" }}>
                {" "}
                {uploadProgress === 100 ? "Uploaded File" : "Uploading File"}
              </b> */}
            </div>
            {responseMsg && (
              <>
                {responseMsg.includes("successfully") ? (
                  <span className="response-msg">
                    <div className="">
                      <SuccessIcon />
                    </div>

                    <div>{responseMsg}</div>
                  </span>
                ) : (
                  <span className="response-msg-error">
                    <div className="">
                      <ErrorIcon />
                    </div>
                    <div>{responseMsg}</div>
                  </span>
                )}
              </>
            )}

            <div
              className={
                bgFile ? "upload-file-green mt-3" : "upload-file-card mt-3"
              }
            >
              <div className="d-flex gap-2 align-items-center justify-content-center p-2 w-100">
                {" "}
                <div>
                  <GoFile color={bgFile ? "#17b05d" : "#c9c9c9"} />
                </div>
                <div className="upload-file-name">{importJobFile.name}</div>
              </div>

              <div>
                {" "}
                {loading ? (
                  <div className="px-2">
                    <BarLoader color="#17b05d" width="150" />
                  </div>
                ) : (
                  <button
                    className="mx-1"
                    style={{ background: "none", border: "none" }}
                    onClick={() => handleClose()}
                  >
                    <IoClose size={20} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h5>Import Job</h5>
          <div className="import-job-sub-content mt-4 mb-1">
            Download the job list template, fill it with your data, and
            effortlessly upload for seamless spreadsheet integration
          </div>{" "}
          <div className="import-job-dragdrop">
            <img src={import1} alt="" className="mb-2" />
            <div className="import-job-dragdropContent">
              <b>
                <span>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <button
                    className="add-job-download-btn"
                    onClick={handleBrowseClick}
                  >
                    Click here to select a file
                  </button>
                </span>{" "}
              </b>
            </div>
            <p style={{ color: "red" }}>{fileError}</p>
            <div className="import-job-sub-content">
              Supported formats .csv, .xlxs - Max file size 10MB
            </div>
          </div>
          <div className="import-download-temp py-3">
            {" "}
            <button className="add-job-download-btn">
              <a
                href={JobOrderList}
                download="Job-Order-Template"
                target="_blank"
                rel="noreferrer"
                className="template-href"
              >
                Download Template
              </a>
            </button>
          </div>
        </>
      )}
      <div className="text-center mt-3">
        <button
          className="close-button-style"
          onClick={() => handleImportJobModal()}
          disabled={btnDisable}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ImportJob;
