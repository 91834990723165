import React, { useEffect, useState, useMemo } from "react";
import "./Auditlog.css";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import DataTable from "react-data-table-component";
import { API } from "../../global";
import toast from "react-hot-toast";
import moment from "moment";
import personIcon from "../../assets/filter-person.svg";
import calender from "../../assets/calendar-filter.svg";
import CanDropdown from "../Reusable/CanDropdown";
import SearchInput from "../Reusable/SearchInput";
import {  toSentenceCase } from "../Functions/commonFunctions";
import { useNavigate } from "react-router";
import LayoutMain from "../Layout/LayoutMain";

const paginationOptions = {
  rowsPerPageText: "Rows per page:",
  rangeSeparatorText: "of",
  // selectAllRowsItem: true,
  // selectAllRowsItemText: '',
  paginationRowsPerPageOptions: [10, 20, 50, 100, 200, 500],
};
const AuditLog = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activityLogData, setActivityLogData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedInterval, setSelectedInterval] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filterData,setFilterData] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [userId, setUserId] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getActivityLogData(null);
    getUserData();
    
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filterData === "") {
      setFilteredData(activityLogData)
      setFilterText('')
    }

    //eslint-disable-next-line
  }, [filterData]);

  const getUserData = () => {
    fetch(`${API}/admin/tenant/user/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          let userList = [];
          // eslint-disable-next-line
          res.result.map((item) => {
            const obj = {
              label: item.name,
              value: item.id,
            };
            userList.push(obj);
          });
          setUserData(userList);
        } else {
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      });
  };

  const getActivityLogData = (user) => {
    setFilterText('')

    setLoading(true);
    fetch(`${API}/admin/audit-logs/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: user ? JSON.stringify(user) : "",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          setLoading(false);
          setActivityLogData(res.result);
        } else {
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      });
  };
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14.5px",
        borderBottom: " 1px solid #eee7f3",
        textAlign: "center",
      },
    },
  };

  const columns = [
    {
      name: "Date & Time",
      cell: (row) => moment(row?.created_at).format("DD MMMM YYYY, h:mma"),
      center: true,
    },
    {
      name: "Name",
      cell: (row) => row?.user_id?.name,
      center: true,
    },
    {
      name: "Module",
      cell: (row) => toSentenceCase(row?.module),
      center: true,
    },
    {
      name: "Activity",
      cell: (row) => toSentenceCase(row?.description),
      center: true,
    },
  ];

  const handleSelect = (type, e) => {
    setFilterData('')
    setFilterText('')
    
    const obj = {};
    if (type === "user") {
      const nameData = userData.filter((item) => e === item.value);
      setSelectedUser(nameData[0]?.label);
      if (e !== "All Users") {
        // if(e !== 'All Users'){
        obj.user_id = e;
        if (dateValue) {
          obj.interval = dateValue;
        }
        setUserId(e);
      }

      // }
    }

    if (type === "date") {
      const dateData = reportArray.filter((item) => item.label === e);
      setSelectedInterval(e);
      // if(e !== 'All Dates'){
      obj.interval = dateData[0]?.key;
      setDateValue(dateData[0]?.key);
      if (userId) {
        obj.user_id = userId;
      }
      // }
    }

    setCurrentPage(1);

    getActivityLogData(obj);
  };

  useEffect(() => {
    console.log("filterText",filterText)
    // Filter the data based on the search query
    const filtered = activityLogData.filter((row) => {
      // Customize this condition based on your search requirements
      return (
        row.user_id?.name?.toLowerCase().includes(filterText?.toLowerCase()) ||
        row?.module?.toLowerCase().includes(filterText?.toLowerCase()) ||
        row?.description?.toLowerCase().includes(filterText?.toLowerCase())
      );
    });
    setFilteredData(filtered);
    //eslint-disable-next-line
  }, [filterText]);

  const handleSearch =()=>{
    console.log("filterData202",filterData)
    setFilterText(filterData)
  }

  const subHeaderComponent = useMemo(() => {
    return (
      <SearchInput
        onFilter={(e) => setFilterData(e.target.value)}
        onSearch={()=>handleSearch()}
        enableOnClick={true}
        filterText={filterData}
      />
    );

    // eslint-disable-next-line
  }, [filterData]);
  const reportArray = [
    // {value:"Lifetime",label :"Lifetime",key : 'lifetime' },
    { value: "Today", label: "Today", key: "today" },
    { value: "Yesterday", label: "Yesterday", key: "yesterday" },
    { value: "Last 7 days", label: "Last 7 days", key: "last-7-days" },
    { value: "Last 30 days", label: "Last 30 days", key: "last-30-days" },
  ];
  // const dateArr = [{ label: "All Dates", value: "All Dates" }];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <div>
      <LayoutMain index={7}>
      {loading ? (
            <>
              <div className="loader-div-job">
                <ProgressBarComponent />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex flex-column w-100 justify-content-between">
                <div
                  className="table-class"
                  style={{
                    background: "#ffffff",
                    margin: "20px",
                    padding: "20px",
                    borderRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-end">
                    <div className=" py-1 px-2 opacity-0">
                      activity-log-data
                    </div>
                    <div className="job-list-label">Activity Log</div>
                    <div className="d-flex gap-3 align-items-end">
                      <div className="">{subHeaderComponent}</div>
                    </div>
                  </div>

                  <div className="serach-div">
                    <p className="search-label">Filters</p>
                    <div style={{ margin: "8px" }}>
                      <CanDropdown
                        width="180px"
                        defaultText="All Users"
                        selectedValue={selectedUser}
                        handleSelectedValue={(data) =>
                          handleSelect("user", data)
                        }
                        DropdownArr={userData}
                        backgroundColor="#F7F7F7"
                        color="#333333"
                        border="#F7F7F7"
                        img={personIcon}
                      />
                    </div>

                    <div style={{ margin: "8px" }}>
                      <CanDropdown
                        width="180px"
                        defaultText="All Dates"
                        selectedValue={selectedInterval}
                        handleSelectedValue={(data) =>
                          handleSelect("date", data)
                        }
                        DropdownArr={reportArray}
                        backgroundColor="#F7F7F7"
                        color="#333333"
                        border="#F7F7F7"
                        img={calender}
                        // disabled={true}
                      />
                    </div>
                  </div>
                  <div className="table-wrap fs-5 ">
                    <DataTable
                      columns={columns}
                      data={filterText ? filteredData : activityLogData}
                      customStyles={customStyles}
                      pagination={activityLogData.length >= 10 ? true : false}
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={
                        paginationOptions.paginationRowsPerPageOptions
                      }
                      paginationTotalRows={
                        filterText
                          ? filteredData.length
                          : activityLogData.length
                      }
                      paginationServer={false}
                      paginationComponentOptions={paginationOptions}
                      onChangePage={handlePageChange}
                      paginationDefaultPage={currentPage}
                    />
                  </div>
                </div>
                
              </div>
            </>
          )}
      </LayoutMain>
      
        
    </div>
  );
};

export default AuditLog;
