import React, { useState } from "react";
import "./AdminDashboard.css";

import totInterview from "../../assets/purpleSchedule.svg";
import totScheduled from "../../assets/blue-yetTostart.svg";
import totInproress from "../../assets/orange-Inprogress.svg";
import totCompleted from "../../assets/green-completed.svg";
import recruitJob from "../../assets/recruitJob.svg";
import recruitCandidate from "../../assets/recruitCandidate.svg";
import playIcon from "../../assets/playIconGreen.svg";
import { API } from "../../global";
import { Tooltip } from "react-tooltip";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import moment from "moment";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";

import CanDropdown from "../Reusable/CanDropdown";
import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";

import GuidedTour from "../Reusable/GuidedTour";
import {
  reportArray,
  stepsAdmin,
  stepsRecruiter,
  stepsTechLead,
} from "../Functions/Constant";
import LayoutMain from "../Layout/LayoutMain";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [topJobOrder, setTopJobOrder] = useState([]);
  const [totalJob, setTotalJob] = useState(0);
  const [totalCan, setTotalCan] = useState(0);
  const [totalInterview, setTotalInterview] = useState(0);
  const [scheduledInterview, setScheduledInterview] = useState(0);
  const [inprogressInterview, setInprogressInterview] = useState(0);
  const [completedInterview, setCompletedInterview] = useState(0);
  const [loading, setLoading] = useState(false);
  const [recentInterviews, setRecentInterviews] = useState([]);
  const [endTime] = useState([]);
  const [totalAiReviews, setTotalAiReviews] = useState(0);
  const [totalFraudDetection, setTotalFraudDetection] = useState(0);
  const [jobselectedValue, setJobSelectedValue] = useState(null);
  const [recruiterselectedValue, setRecruiterSelectedValue] = useState(null);
  const [ReportselectedValue, setReportSelectedValue] = useState(null);
  const [hideRecruiterDropdown, setHideRecruiterDropDown] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [recruiterList, setRecruiterList] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [userFilterLoading, setUserFilterLoading] = useState(false);
  const [intervalLoading, setIntervalLoading] = useState(false);
  const [jobId, setJobId] = useState("");
  const [userId, setUserId] = useState("");
  const [intervalData, setIntervalData] = useState("");

  const [recruitmentData, setRecruitmentData] = useState("");
  const [steps, setSteps] = useState([]);
  const [showGuid, setShowGuid] = useState(false);

  const [run, setRun] = useState(true); // Set run to true by default

  useEffect(() => {
    const tourGuide = localStorage.getItem("tourGuid");
    if (tourGuide === "true") {
      handleTour();
    }

    // eslint-disable-next-line
  }, []);
  const handleTour = () => {
    const obj = {
      show_guided_tour: true,
    };

    setTimeout(() => {
      setShowGuid(true);
    }, 2000);
    localStorage.setItem("tourGuid", false);
    fetch(`${API}/admin/profile/settings/show-guided-tour`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          setRun(true);
          localStorage.setItem("show_guided_tour", true);
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(val.error.msg);
          }
        }
      });
  };

  const user_role = localStorage.getItem("current_role_key");

  useEffect(() => {
    getJobs();
    filterData(null, null);
    getRecruiterData();

    if (user_role === "talentou_admin") {
      setHideRecruiterDropDown(true);
      setSteps(stepsAdmin());
    } else if (user_role === "talentou_tech_lead") {
      setHideRecruiterDropDown(false);
      setSteps(stepsTechLead());
    } else {
      setHideRecruiterDropDown(false);
      setSteps(stepsRecruiter());
    }

    setTimeout(() => {
      setShowGuid(true);
    }, 4000);

    // eslint-disable-next-line
  }, [user_role]);

  const convertMinutesToHours = (min) => {
    let hours = min / 60; // Convert minutes to hours in decimal format

    if (hours.toFixed(1) === "0.0") {
      return "0 hr";
    } else {
      return hours.toFixed(1) + " hrs"; // Display hours in decimal format with one decimal place
    }
  };

  const getJobs = () => {
    setLoading(true);
    // setRun(false);
    fetch(`${API}/admin/job-order/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        let listJOb = [];
        if (val.success === true) {
          // eslint-disable-next-line
          val.result.map((item) => {
            const obj = {
              value: item.job_role,
              label: item.job_role,
              id: item._id,
            };
            listJOb.push(obj);
          });

          const jobIntialId = val?.result[0]?._id;
          if (!jobIntialId) {
            localStorage.setItem("jobIntialId", "");
          } else {
            localStorage.setItem("jobIntialId", jobIntialId);
          }

          setJobList(listJOb);
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(val.error.msg);
          }
        }
      });
  };

  const getRecruiterData = () => {
    fetch(`${API}/admin/tenant/user/list?role_key=talentou_recruiter`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        let listRecruiter = [];
        if (val.success === true) {
          // eslint-disable-next-line
          val.result.map((item) => {
            const obj = {
              value: item.name,
              label: item.name,
              id: item._id,
            };
            listRecruiter.push(obj);
          });
          setRecruiterList(listRecruiter);
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(val.error.msg);
          }
        }
      });
  };

  const handleReportSelectedValue = (eventKey) => {
    setIntervalLoading(true);

    const intervalData = reportArray().filter(
      (item) => item.label === eventKey
    );
    setReportSelectedValue(eventKey);
    filterData("interval", intervalData[0]?.key);
    setIntervalData(intervalData[0]?.key);
  };
  const handleRecruiterSelectedValue = (eventKey) => {
    setUserFilterLoading(true);

    setRecruiterSelectedValue(eventKey);
    const recruiter = recruiterList.filter((item) => item.label === eventKey);
    // setRecruiterId(recruiter[0].id)
    filterData("user", recruiter[0]?.id);
    setUserId(recruiter[0]?.id);
  };

  const handleJobSelectedValue = (eventKey) => {
    setFilterLoading(true);
    setJobSelectedValue(eventKey);
    const jobData = jobList.filter((item) => item.label === eventKey);
    filterData("job", jobData[0]?.id);
    setJobId(jobData[0]?.id);
  };

  const filterData = (type, id) => {
    const obj = {};
    if (type === "job") {
      obj.job_order_id = id;
      if (userId) {
        obj.user_id = userId;
      }
      if (intervalData) {
        obj.interval = intervalData;
      }
    }

    if (type === "user") {
      obj.user_id = id;
      if (jobId) {
        obj.job_order_id = jobId;
      }

      if (intervalData) {
        obj.interval = intervalData;
      }
    }

    if (type === "interval") {
      obj.interval = id;
      if (userId) {
        obj.user_id = userId;
      }

      if (jobId) {
        obj.job_order_id = jobId;
      }
    }

    fetch(`${API}/admin/dashboard/interview-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        setFilterLoading(false);
        setUserFilterLoading(false);
        setIntervalLoading(false);
        setTotalInterview(val.result.interviews_scheduled);
        setScheduledInterview(val.result.interviews_yet_to_start);
        setInprogressInterview(val.result.interviews_in_progress);
        setCompletedInterview(val.result.interviews_completed);
        const secondsFraudDetection = val.result.total_fraud_detection_minutes;
        setTotalAiReviews(val.result.total_ai_reviews);
        setTotalFraudDetection(convertMinutesToHours(secondsFraudDetection));
      });

    fetch(`${API}/admin/dashboard/recruitment-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        setFilterLoading(false);

        if (res.success === true) {
          setFilterLoading(false);
          setUserFilterLoading(false);
          setIntervalLoading(false);
          setLoading(false);
          // setRun(false);
          setTotalJob(res.result.total_job_orders);
          setTotalCan(res.result.total_candidates);
          setTopJobOrder(res.result.top_job_orders);
          setRecentInterviews(res.result.recent_interviews);
          handleRecruitmentData(res.result);
        } else {
          if (res.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(res.error.msg);
          }
        }
      });
  };

  const handleRecruitmentData = (res) => {
    let recruitData = res.recruitment_funnel
      .filter((item) => item._id !== null)
      .sort((a, b) => b.recruitment_funnel - a.recruitment_funnel);

    // Define the default data structure
    const defaultRecruitData = [
      { name: "No Contact", value: 0 },
      { name: "Contacted", value: 0 },
      { name: "Interviewing", value: 0 },
      { name: "Offered", value: 0 },
      { name: "Placed", value: 0 },
      { name: "Declined", value: 0 },
    ];

    let renamedRecruitData = [];

    if (recruitData.length !== 0) {
      const recruitDataList = reArrangeData(res);
      // Create a map from the API response for easy lookup
      const apiDataMap = recruitDataList.reduce((acc, item) => {
        acc[item._id] = item.recruitment_funnel;
        return acc;
      }, {});

      // Map the default data and overwrite values from the API response
      renamedRecruitData = defaultRecruitData.map((item) => ({
        name: item.name,
        value: apiDataMap[item.name] || item.value,
      }));
    } else {
      // If no data is received, use the default structure
      renamedRecruitData = defaultRecruitData;
    }

    setRecruitmentData(renamedRecruitData);
  };

  const reArrangeData = (res) => {
    const funnelData = [];
    let noContactData = {};
    let contactedData = {};
    let interviewingData = {};
    let declinedData = {};
    let placedData = {};
    let offeredData = {};

    let noContactCount = 0;
    let contactedCount = 0;
    let interviewingCount = 0;
    let declinedCount = 0;
    let placedCount = 0;
    let offeredCount = 0;

    // eslint-disable-next-line
    res.recruitment_funnel.map((data) => {
      if (data._id === null || data._id === "" || data._id === "No Contact") {
        noContactCount += data.recruitment_funnel;
        noContactData = {
          _id: "No Contact",
          recruitment_funnel: noContactCount,
        };
      } else if (
        data._id === "Not in Consideration" ||
        data._id === "Declined" ||
        data._id === "Client Declined"
      ) {
        declinedCount += data.recruitment_funnel;
        declinedData = {
          _id: "Declined",
          recruitment_funnel: declinedCount,
        };
      } else if (
        data._id === "Contacted" ||
        data._id === "Candidate Responded"
      ) {
        contactedCount += data.recruitment_funnel;
        contactedData = {
          _id: "Contacted",
          recruitment_funnel: contactedCount,
        };
        funnelData.push(contactedData);
      } else if (
        data._id === "Interviewing" ||
        data._id === "Qualifying" ||
        data._id === "Submitted"
      ) {
        interviewingCount += data.recruitment_funnel;
        interviewingData = {
          _id: "Interviewing",
          recruitment_funnel: interviewingCount,
        };
        funnelData.push(interviewingData);
      } else if (data._id === "Offered") {
        offeredCount += data.recruitment_funnel;
        offeredData = {
          _id: "Offered",
          recruitment_funnel: offeredCount,
        };
      } else if (data._id === "Placed") {
        placedCount += data.recruitment_funnel;
        placedData = {
          _id: "Placed",
          recruitment_funnel: placedCount,
        };
      }
    });
    let funnelDataList = [];
    funnelDataList = [
      ...funnelDataList,
      noContactData,
      contactedData,
      interviewingData,
      offeredData,
      placedData,
      declinedData,
    ];

    return funnelDataList;
  };

  const LegendItem = ({ label, color }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        // marginBottom: "5px",
      }}
    >
      <div
        style={{
          width: "7px",
          height: "30px",
          backgroundColor: color,
          marginRight: "5px",
        }}
      ></div>
      <div>{label}</div>
    </div>
  );

  const legendData = [
    { label: "No Contact", color: "#FFA289" },
    { label: "Contacted", color: "#FFC9A2" },
    { label: "Interviewing", color: "#9CD8FF" },
    { label: "Offered", color: "#E9D6FE" },
    { label: "Placed", color: "#96F7C7" },
    { label: "Declined", color: "#C7C7FF" },
    // Add more stages as needed
  ];
  const closeGuid = () => {
    setShowGuid(false);
  };

  return (
    <LayoutMain index={1}>
      {loading ? (
        <div className="loader-div-job">
          <ProgressBarComponent />
        </div>
      ) : (
        <div className="admin-section-container mt-3">
          <div>
            <div className="d-flex justify-content-between align-items-center px-4">
              <div className="admin-dashboard-title">Dashboard</div>

              <div className="d-flex gap-2">
                <CanDropdown
                  width="180px"
                  defaultText="All Job Orders"
                  selectedValue={jobselectedValue}
                  handleSelectedValue={handleJobSelectedValue}
                  DropdownArr={jobList}
                  backgroundColor="#F8EBFF"
                  color="#600097"
                  border="#F0D4FF"
                  loading={filterLoading}
                />
                {hideRecruiterDropdown ? (
                  <CanDropdown
                    width="180px"
                    defaultText="All Recruiters"
                    selectedValue={recruiterselectedValue}
                    handleSelectedValue={handleRecruiterSelectedValue}
                    DropdownArr={recruiterList}
                    backgroundColor="#F8EBFF"
                    color="#600097"
                    border="#F0D4FF"
                    loading={userFilterLoading}
                  />
                ) : (
                  ""
                )}

                <CanDropdown
                  width="180px"
                  defaultText="Lifetime"
                  selectedValue={ReportselectedValue}
                  handleSelectedValue={handleReportSelectedValue}
                  DropdownArr={reportArray()}
                  backgroundColor="#F8EBFF"
                  color="#600097"
                  border="#F0D4FF"
                  loading={intervalLoading}
                />
              </div>
            </div>
            <div className="admin-main-interview-status-container mx-4 pb-4 pt-3">
              <div className="admin-interview-status px-2 mx-1">
                <div className="status-count-container">
                  <div className="status-count">{totalInterview}</div>
                  <div className="status-text">Total Scheduled</div>
                </div>
                <div className="img-container mt-4">
                  <img src={totInterview} alt="" />
                </div>
              </div>
              <div className="admin-interview-status px-2 mx-1">
                <div className="status-count-container">
                  <div className="status-count">{scheduledInterview}</div>
                  <div className="status-text">Yet to Start</div>
                </div>
                <div className="img-container mt-4">
                  <img src={totScheduled} alt="" />
                </div>
              </div>
              <div className="admin-interview-status px-2 mx-1">
                <div className="status-count-container">
                  <div className="status-count">{inprogressInterview}</div>
                  <div className="status-text">In Progress</div>
                </div>
                <div className="img-container mt-4">
                  <img src={totInproress} alt="" />
                </div>
              </div>
              <div className="admin-interview-status px-2 mx-1">
                <div className="status-count-container">
                  <div className="status-count">{completedInterview}</div>
                  <div className="status-text">Completed</div>
                </div>
                <div className="img-container mt-4">
                  <img src={totCompleted} alt="" />
                </div>
              </div>
              <div className="admin-interview-status px-2">
                <p className="interview-status-title">Interview Status</p>
              </div>
            </div>

            <div className="admin-main-recruitment-data-container mx-4 pb-4 pt-4">
              <div
                className={`${
                  jobId ||
                  (recruiterselectedValue !== null &&
                    recruiterselectedValue !== "All Recruiters")
                    ? "admin-recruitement-data px-2 mx-1 opacity-25"
                    : "admin-recruitement-data  px-2 mx-1 "
                } `}
              >
                <div className="data-count-container py-1">
                  <div className="status-count">{jobId ? "1" : totalJob}</div>
                  <div className="data-text">
                    {totalJob === 1 ? "Job Order" : "Job Orders"}
                  </div>
                </div>
                <div className="img-container mt-4">
                  <img src={recruitJob} alt="" />
                </div>
              </div>
              <div
                className={`${
                  recruiterselectedValue === null ||
                  recruiterselectedValue === "All Recruiters"
                    ? "admin-recruitement-data  px-2 mx-1"
                    : "admin-recruitement-data  px-2 mx-1  opacity-25"
                }`}
              >
                <div className="data-count-container py-1">
                  <div className="status-count">{totalCan}</div>
                  <div className="data-text">
                    {totalCan === 1 ? "Candidate" : "Candidates"}
                  </div>
                </div>
                <div className="img-container mt-4">
                  <img src={recruitCandidate} alt="" />
                </div>
              </div>
              <div
                className={`${
                  recruiterselectedValue === null ||
                  recruiterselectedValue === "All Recruiters"
                    ? "admin-recruitement-data  px-2 mx-1"
                    : "admin-recruitement-data  px-2 mx-1 opacity-25"
                }`}
                style={{ justifyContent: "center" }}
              >
                <div className="py-1">
                  <div className="status-count-font">{totalFraudDetection}</div>
                  <div className="data-text">Total Fraud Detection Hours</div>
                </div>
              </div>
              <div
                className={`${
                  recruiterselectedValue === null ||
                  recruiterselectedValue === "All Recruiters"
                    ? "admin-recruitement-data  px-2 mx-1 "
                    : "admin-recruitement-data  px-2 mx-1 opacity-25"
                }`}
                style={{ justifyContent: "center" }}
              >
                <div className="py-1">
                  <div className="status-count-font">{totalAiReviews}</div>
                  <div className="data-text">Total AI Reviews</div>
                </div>
              </div>
              <div className="admin-recruitement-data  px-2">
                <p className="interview-status-title ">Job Status</p>
              </div>
            </div>

            <div className="admin-bottom-main-container mx-4 mt-4 ">
              <div className="admin-recruitement-funnel-container">
                <div className="recruit-funnel-title">
                  <div className="py-1 admin-job-title">Recruitment Funnel</div>
                </div>

                <div className="recruitment-chart-container h-75 px-3 py-3 mt-4">
                  <div className="funnel-chart-container">
                    <FunnelChart
                      data={recruitmentData}
                      showValues={true}
                      showNames={false}
                      pallette={[
                        "#FFA289",
                        "#FFC9A2",
                        "#9CD8FF",
                        "#E9D6FE",
                        "#96F7C7",
                        "#C7C7FF",
                      ]}
                      getToolTip={(row) => {
                        return row.name + " , " + row.value;
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    {/* Adjust position as needed */}
                    {legendData.map((item, index) => (
                      <LegendItem
                        key={index}
                        label={item.label}
                        color={item.color}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="admin-top-job-orders-container">
                <div className="py-1 admin-job-title">Top Job Orders</div>

                <div className="admin-top-job-order-content h-75 px-2 py-3 mt-4">
                  {topJobOrder.length === 0 || jobId ? (
                    <div className="no-records-found">
                      There are no records to display
                    </div>
                  ) : (
                    <table
                      className={
                        jobId
                          ? "top-job-order-table opacity-25"
                          : "top-job-order-table"
                      }
                    >
                      <tbody>
                        {topJobOrder.map((t, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              <div className="recent-interview-td">
                                {t.job_role}
                              </div>
                            </td>
                            <td className="applicant-td">
                              {t.candidate_count}{" "}
                              {t.candidate_count === 1
                                ? "Candidate"
                                : "Candidates"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              <div className="admin-recent-interview-container">
                <div className="py-1 admin-job-title">Recent Interviews</div>

                <div className="admin-top-job-order-content h-75 px-2 py-3 mt-4">
                  <Tooltip
                    id="admin-dashboard-tooltip"
                    variant="light"
                    className="candidate-tooltip"
                    border="1px solid #7C7B7B"
                  />

                  {recentInterviews.length === 0 ? (
                    <div className="no-records-found">
                      There are no records to display
                    </div>
                  ) : (
                    <table className="top-job-order-table">
                      <tbody>
                        {Array.from(
                          {
                            length: Math.max(
                              recentInterviews.length,
                              endTime.length
                            ),
                          },
                          (_, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                <div
                                  className="recent-interview-td"
                                  data-tooltip-id="admin-dashboard-tooltip"
                                  data-tooltip-content={`${recentInterviews[i]?.candidate_id?.name} (${recentInterviews[i]?.job_order_id?.job_role})`}
                                  data-tooltip-place="top"
                                  style={{ cursor: "pointer" }}
                                >
                                  {recentInterviews[i]?.candidate_id?.name}(
                                  {recentInterviews[i]?.job_order_id?.job_role})
                                </div>
                              </td>

                              <td>
                                <button
                                  style={{
                                    border: "none",
                                    background: "none",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/interview/view-recordings/${recentInterviews[i].interview_id}`
                                    )
                                  }
                                >
                                  <img
                                    src={playIcon}
                                    data-tooltip-id="admin-dashboard-tooltip"
                                    data-tooltip-content="Play Recording"
                                    data-tooltip-place="top"
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                  />
                                </button>
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {moment(
                                  recentInterviews[i]?.interview_finish_date
                                ).fromNow()}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          {showGuid && (
            <GuidedTour
              steps={steps}
              setRun={setRun}
              run={run}
              closeGuid={closeGuid}
            />
          )}
        </div>
      )}
    </LayoutMain>

    // </div>
  );
};

export default AdminDashboard;
